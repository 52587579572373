import {CanActivateFn, Router} from '@angular/router';
import {CommonService} from "../services/common.service";
import {inject} from "@angular/core";
import {Store} from "@ngrx/store";
import * as fromRoot from "../../../state/app.state";
import {profile} from "../core/shared.selectors";
import {roles} from "../constants/common.constants";

export const clientAdminGuard: CanActivateFn = (route, state) => {
  const commonService: CommonService = inject(CommonService);
  const store: Store = inject(Store<fromRoot.State>);
  const router: Router = inject(Router);

  let role = '';

  store.select(profile).subscribe((data) => {
    role = data.role;
  }).unsubscribe();

  if (role === roles.clientAdmin) {
    return true;
  }

  router.navigate([commonService.getBaseRoute()]);
  return false;
};
