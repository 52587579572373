<div class="component-container flex flex-col justify-center items-center">
  <div class="content w-full flex flex-col justify-center items-center">
    <form class="flex flex-col xs:px-0 md:px-[2rem] lg:px-[8rem] w-full">
      <div
        *ngIf="!onClientsProfilePage && role ==='Admin'"
        class="flex flex-col sm:flex-row justify-center items-center sm:gap-[1.5rem]"
      >
        <div
          class="label sm:w-1/3 w-full flex sm:justify-end justify-start sm:mb-[2rem]"
        >
          Client
        </div>
        <app-search-on-select
          (checkForErrors)="checkForErrors($event)"
          [error]="errorMessages['client']"
          [loading]="clientFilterOptions.loading"
          [options]="clientFilterOptions.list"
          [parentFormGroup]="form"
          class="input sm:w-2/3 w-full"
          controlName="client"
          filterControlName="searchClient"
          placeholder="Select a Client"
        ></app-search-on-select>
      </div>
      <div
        class="flex flex-col sm:flex-row justify-center items-center sm:gap-[1.5rem]"
      >
        <div
          class="label sm:w-1/3 w-full flex sm:justify-end justify-start sm:mb-[2rem]"
        >
          Name
        </div>
        <app-text-input
          [error]="errorMessages['name']"
          [parentFormGroup]="form"
          class="input sm:w-2/3 w-full"
          controlName="name"
          placeholder="Enter Name"
        ></app-text-input>
      </div>
      <div
        class="flex flex-col sm:flex-row justify-center items-center sm:gap-[1.5rem]"
      >
        <div
          class="label sm:w-1/3 w-full flex sm:justify-end justify-start sm:mb-[2rem]"
        >
          Email Address
        </div>
        <app-text-input
          [error]="errorMessages['emailId']"
          [parentFormGroup]="form"
          class="input sm:w-2/3 w-full"
          controlName="emailId"
          placeholder="Enter Email Id"
        ></app-text-input>
      </div>
      <div
        class="flex flex-col sm:flex-row justify-center items-center sm:gap-[1.5rem]"
      >
        <div
          class="label sm:w-1/3 w-full flex sm:justify-end justify-start sm:mb-[2rem]"
        >
          Role
        </div>
        <app-search-on-select
          (checkForErrors)="checkForErrors($event)"
          [error]="errorMessages['role']"
          [loading]="roleFilterOptions?.loading"
          [options]="roleFilterOptions?.list"
          [parentFormGroup]="form"
          class="input sm:w-2/3 w-full"
          controlName="role"
          filterControlName="searchRole"
          placeholder="Select Role"
        ></app-search-on-select>
      </div>
      <div class="flex flex-row justify-start items-center gap-[1.5rem]">
        <div
          class="label sm:w-1/3 xs:w-1/6 flex sm:justify-end justify-start mb-[2rem]"
        >
          Active
        </div>
        <mat-slide-toggle
          (change)="toggle($event)"
          [checked]="!!isActive"
          class="input toggle sm:w-2/3 xs:w-1/6"
        >
        </mat-slide-toggle>
      </div>
      <div
        class="flex flex-col sm:flex-row justify-start items-start gap-[2rem]"
      >
        <div class="label sm:w-1/3 w-full flex sm:justify-end justify-start">
          Profile Image
        </div>
        <div class="input sm:w-2/3 w-full flex flex-col">
          <div
            class="thumbnail-section mb-[1rem] flex xs:justify-center xs:items-center"
          >
            <app-image
              [url]="thumbnailUrl"
              class="w-[15rem] h-[15rem] block border"
            ></app-image>
          </div>
          <div class="thumbnail-action-section flex flex-row gap-[1rem]">
            <app-primary-button
              (handleClick)="openFile('thumbnailUploadRef')"
              class="w-4/12"
            >
              Browse
            </app-primary-button>
            <app-text-input
              (checkForErrors)="checkForErrors('logo')"
              [error]="errorMessages['logo']"
              [parentFormGroup]="form"
              class="w-8/12"
              controlName="logo"
              placeholder="Select an Image"
            ></app-text-input>
            <input
              #thumbnailUploadRef
              (change)="handleThumbnailUpload()"
              [id]="'thumbnailUploadRef'"
              class="input-files"
              type="file"
            />
          </div>
        </div>
      </div>
      <div
        *ngIf="(this.userData || this.userProfileDetails)"
        class="flex flex-row justify-start items-center gap-[1.5rem]">
        <div
          class="label sm:w-1/3 xs:w-1/6 flex sm:justify-end justify-start mb-[2rem]"
        >
          Update Password
        </div>
        <mat-slide-toggle
          (change)="togglePasswordClicked($event)"
          [checked]="isUpdatePasswordClicked"
          class="input toggle sm:w-2/3 xs:w-1/6"
        >
        </mat-slide-toggle>
      </div>
      <div
        *ngIf="displayPasswordSection"
        class="password-section"
      >
        <div class="sub-heading">Password</div>
        <div
          class="flex flex-col sm:flex-row justify-center items-center sm:gap-[1.5rem]"
        >
          <div
            class="flex-row gap-[1rem] label sm:w-1/3 w-full flex sm:justify-end justify-start items-center sm:mb-[2rem]"
          >
            <i
              [matTooltip]="tooltipMessage()"
              class="fa fa-info-circle password"
            ></i>
            <div>Password</div>
          </div>
          <app-password-input
            (checkForErrors)="checkForErrors($event)"
            [displayRedirectLink]="false"
            [error]="errorMessages['password']"
            [parentFormGroup]="form"
            class="input sm:w-2/3 w-full"
            controlName="password"
            placeholder="Password"
          ></app-password-input>
        </div>
        <div
          class="flex flex-col sm:flex-row justify-center items-center sm:gap-[1.5rem]"
        >
          <div
            class="label sm:w-1/3 w-full flex sm:justify-end justify-start sm:mb-[2rem]"
          >
            Confirm Password
          </div>
          <app-password-input
            [error]="errorMessages['confirmPassword']"
            [parentFormGroup]="form"
            class="input sm:w-2/3 w-full"
            controlName="confirmPassword"
            placeholder="Re-Enter Password"
          ></app-password-input>
        </div>
      </div>

    </form>
    <div
      class="action-section w-[80%] sm:w-1/3 flex flex-row justify-center items-center {{(userData|| userProfileDetails) && !displayPasswordSection ? 'mt-[5rem]':''}}"
    >
      <app-primary-button
        (handleClick)="save()"
        [isDisabled]="!form.dirty"
        class="w-full"
      >
        Save
      </app-primary-button>
    </div>
  </div>
</div>
