export const environment = {
  production: false,
  env: 'development',
  jasperEnv: 'dev',
  apiURL: 'https://devbackend.schoolvoices360.com',
  jasperServerUrl: 'https://my360.schoolvoices360.com:8447/jasperserver-pro/client/visualize.js?_opt=true',
  jasperServerUsername: 'jasperadmin',
  jasperServerPassword: 'BlueDiamond@123'
};

