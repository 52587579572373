import {ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {Store} from "@ngrx/store";
import * as fromRoot from "../../../../state/app.state";
import {Subject, takeUntil} from "rxjs";
import {profile, screenType, sidenavOpenCounter} from "../../core/shared.selectors";
import {MatSidenav} from "@angular/material/sidenav";
import {CommonService} from "../../services/common.service";
import {MarkSidenavClosed, MarkSidenavOpen} from "../../core/shared.actions";

@Component({
  selector: 'app-sidenav-container',
  templateUrl: './sidenav-container.component.html',
  styleUrls: ['./sidenav-container.component.css']
})
export class SidenavContainerComponent {
  @ViewChild('drawer') drawer: MatSidenav;
  isDrawerOpen: boolean = false;
  screenType = '';
  showSidenavButton: boolean = true;
  sidenavOpenCount: number;
  role: any;
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private store: Store<fromRoot.State>,
    private cdRef: ChangeDetectorRef,
    private commonService: CommonService
  ) {
    this.store.select(screenType)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.screenType = data;

        if (this.drawer && this.screenType) {
          if (this.screenType === 'desktop') {
            this.drawer?.open();
            this.cdRef.detectChanges();
          } else {
            this.drawer?.close();
            this.cdRef.detectChanges();
          }
        }
      });

    this.store.select(profile)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.role = data?.role
      })

    this.commonService.setSidenavButtonVisibility
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.showSidenavButton = data;
      });

    this.store.select(sidenavOpenCounter)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.sidenavOpenCount = data?.length;
      })
  }

  drawerOpenStarted() {
    this.isDrawerOpen = true;
    this.store.dispatch(MarkSidenavOpen());
  }

  drawerCloseStarted() {
    this.isDrawerOpen = false;
    this.store.dispatch(MarkSidenavClosed());
  }

  ngAfterViewInit() {
    if (this.screenType === 'desktop') {
      this.drawer?.open();
      this.cdRef.detectChanges();
    } else {
      this.drawer?.close();
      this.cdRef.detectChanges();
    }
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
    this.cdRef.detach();
  }
}
