/* NgRx */
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ReportsState} from './reports.reducer';

// Selector functions
const stateData =
  createFeatureSelector<ReportsState>('school-voices.reports');

export const dashboardsList = createSelector(
  stateData,
  (state: ReportsState) => {
    return JSON.parse(JSON.stringify(state.dashboardsList)).sort((a,b) => a.order - b.order);
  }
);

export const schoolsData = createSelector(
  stateData,
  (state: ReportsState) => state.schoolsData
);

export const schoolYearsData = createSelector(
  stateData,
  (state: ReportsState) => state.schoolYearsData
);

export const timePeriodData = createSelector(
  stateData,
  (state: ReportsState) => state.timePeriodData
);

export const selectedReport = createSelector(
  stateData,
  (state: ReportsState) => state.selectedReport
);

export const clientsData = createSelector(
  stateData,
  (state: ReportsState) => state.clientsData
);

export const clientVersionData = createSelector(
  stateData,
  (state: ReportsState) => state.clientVersionData
);

export const stakeholderData = createSelector(
  stateData,
  (state: ReportsState) => state.stakeholderData
);

export const combinedScoreData = createSelector(
  stateData,
  (state: ReportsState) => state.combinedScoreData
);

export const masterSurveyFilterInfo = createSelector(
  stateData,
  (state: ReportsState) => state.masterSurveyFilterInfo
);
