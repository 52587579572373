<mat-sidenav-container
  class="page-wrapper">
  <mat-sidenav
    #drawer
    (closedStart)="drawerCloseStarted()"
    (openedStart)="drawerOpenStarted()"
    *ngIf="role !== 'ClientUser'"
    [mode]="screenType === 'desktop' ? 'side' : 'over'"
    [opened]="screenType === 'desktop'"
    class="sidenav-section sidenav-outlet w-[26rem] min-h-full"
  >
    <router-outlet name="sidenav"></router-outlet>
  </mat-sidenav>

  <mat-sidenav-content
    class="content-section min-h-full"
  >
    <div
      (click)="drawer.toggle()"
      *ngIf="showSidenavButton && role !== 'ClientUser'"
      class="sidenav-button flex flex-row items-center justify-center"
    >
      <app-icon
        [icon]="isDrawerOpen ? 'keyboard_arrow_left' : 'keyboard_arrow_right'"
      ></app-icon>
    </div>
    <router-outlet name="content"></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
