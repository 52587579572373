<form
  *ngIf="enableSelector && showSurveyTranslate; else showGoogleTranslate"
  class="language-form"
  [formGroup]="form"
>
  <app-dropdown
    class="language-dropdown"
    [parentFormGroup]="form"
    controlName="language"
    placeholder="Select Language"
    [options]="options"
  ></app-dropdown>
</form>
<ng-template #showGoogleTranslate
>
  <app-google-translate
    *ngIf="showGoogleTranslate"
  />
</ng-template>
