import {ChangeDetectorRef, Component} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {CommonService} from "../../services/common.service";
import {Subject, take, takeUntil} from "rxjs";
import {Store} from "@ngrx/store";
import * as fromRoot from "../../../../state/app.state";
import {
  selectedReport
} from "../../../page-modules/reports/core/reports.selectors";
import * as $ from 'jquery';
import {sidenavOpenCounter} from "../../core/shared.selectors";

@Component({
  selector: 'app-visualize-js',
  templateUrl: './visualize-js.component.html',
  styleUrls: ['./visualize-js.component.css']
})
export class VisualizeJsComponent {
  private readonly onDestroy: Subject<any> = new Subject<any>();

  environment = environment;

  reportElement: HTMLElement;
  initialZoom: number = 1.2;
  reportParams: any;

  showDownloadButton = false;

  constructor(
    private commonService: CommonService,
    private store: Store<fromRoot.State>,
  ) {
    this.commonService.loadScript(environment.jasperServerUrl);

    this.store.select(selectedReport)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        if (data) {
          $('#report_container').empty();
          $('#download_container').empty();
        }
      });

    this.commonService.triggerReport
      .pipe(takeUntil(this.onDestroy))
      .subscribe((params) => {
        this.store.select(selectedReport)
          .subscribe(data => {
            this.reportParams = {
              IS_IGNORE_PAGINATION: ['true']
            };

            if (data?.info) {
              this.commonService.startLoading();
              data.info.vjsParams.map((o) => {
                switch (o.component_out_param) {
                  case "SelectedMasterSurveyId":
                    this.reportParams[o.report_param] = [params.masterSurvey];
                    break;
                  case "ClientSurveyVersionId":
                    this.reportParams[o.report_param] = params.clientSurveyVersion;
                    break;
                  case "StakeholderGroupsToInclude":
                    this.reportParams[o.report_param] = [params.stakeholder.join(',')];
                    break;
                  case 'ClientId':
                    this.reportParams[o.report_param] = [params.clientId];
                    break;
                  case 'SelectedSchool':
                    this.reportParams[o.report_param] = [params.schoolId];
                    break;
                  case 'SelectedSchools':
                    this.reportParams[o.report_param] = [params.schoolsList.join(',')];
                    break;
                  case 'SelectedSchoolYear':
                    this.reportParams[o.report_param] = [params.yearId];
                    break;
                  case 'SelectedTimePeriod':
                    this.reportParams[o.report_param] = [params.timePeriod];
                    break;
                  case 'IncludeOnlySelectedInCombinedScores':
                    this.reportParams[o.report_param] = [params.stakeholderCombinedScore.join(',')];
                    break;
                  case 'ToggleSatisfactionDissatisfaction':
                    this.reportParams[o.report_param] = [params.satisfaction];
                    break;
                }
              });

              this.reportParams = {
                ...data.info,
                reportParams: this.reportParams
              }

              this.loadReport();
            }
          })
          .unsubscribe();
      });

    this.store.select(sidenavOpenCounter)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        const reportElement = $('.jrPage')[0];

        if (reportElement) {
          setTimeout(() => {
            this.commonService.centerReport(reportElement);
          }, 200)
        }
      })
  }

  loadReport() {
    const params = this.reportParams;
    params.reportParams.pReportOutputFormat = ['html']

    if (window['visualize']) {
      const visualize = window['visualize'];

      visualize({
        auth: {
          name: this.environment.jasperServerUsername,
          password: this.environment.jasperServerPassword,
        }
      }, (v) => {
        v('#report_container').report({
          resource: `/${environment.jasperEnv}${params.reportPath}`,
          scale: 'width',
          container: '#report_container',
          params: params.reportParams,
          success: (o) => {
            this.reportElement = $('.jrPage')[0];
            this.initialZoom = 1;
            $(this.reportElement).css('transform', `scale(${this.initialZoom})`);
            this.commonService.centerReport(this.reportElement);
            this.commonService.stopLoading();
            this.showDownloadButton = true;
            console.info('Report run successfully.');
          },
          error: (e) => {
            this.commonService.stopLoading();
            this.commonService.notification(e.message, 'danger')
          },
        })
      })
    } else {
      setTimeout(() => {
        this.loadReport();
      }, 1000);
    }
  }

  downloadReport (format) {
    this.commonService.startLoading();
    const params = this.reportParams;
    params.reportParams.pReportOutputFormat = [format];
    params.reportParams.Stop_Transition = ['Yes'];

    if (window['visualize']) {
      const visualize = window['visualize'];

      visualize({
        auth: {
          name: this.environment.jasperServerUsername,
          password: this.environment.jasperServerPassword,
        }
      }, (v) => {
        const report = v.report({
          resource: `/${environment.jasperEnv}${params.reportPath}`,
          scale: 'width',
          container: '#download_container',
          params: params.reportParams,
          runImmediately: false,
          ignorePagination: false,
          success: (o) => {
            this.commonService.stopLoading();
            console.info('Report run successfully.');
          },
          error: (e) => {
            this.commonService.stopLoading();
            this.commonService.notification(e.message, 'danger')
          }
        })

        report.run()
          .done((obj) => {
            report.export({
              outputFormat: format
            }, (exportData) => {
              this.commonService.openInNewTab(exportData.href);
              this.commonService.stopLoading();
            })
          })
      })
    } else {
      setTimeout(() => {
        this.commonService.stopLoading();
        this.downloadReport(format);
      }, 1000);
    }
  }

  setZoomLevel(value) {
    this.initialZoom = value;
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}
