import {ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild,} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, Validators,} from '@angular/forms';
import {debounceTime, Subject, takeUntil} from 'rxjs';
import {CommonService} from '../../services/common.service';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../state/app.state';
import * as Validation from '../../constants/validation.constants';
import {password} from '../../constants/validation.constants';
import {
  AddNewUserRequest,
  FetchClientFilterRequest,
  FetchRoleFilterRequest,
  UpdateUserRequest,
} from '../../core/shared.actions';
import {clientFilter, profile, roleFilter} from '../../core/shared.selectors';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {Router} from "@angular/router";

@Component({
  selector: 'app-add-or-edit-user',
  templateUrl: './add-or-edit-user.component.html',
  styleUrls: ['./add-or-edit-user.component.css'],
})
export class AddOrEditUserComponent implements OnInit {
  @Input() data;
  @ViewChild('thumbnailUploadRef') thumbnailUploadRef: ElementRef;
  validation = Validation;
  userData: any;
  userProfileDetails: any;
  displayPasswordSection: boolean = false;
  errorMessages = {};
  errorMessageMap = {
    client: {
      required: 'Required',
    },
    searchClient: {},
    name: {
      required: 'Required',
    },
    emailId: {
      required: 'Required',
      pattern: 'Invalid Email',
    },
    clientType: {
      required: 'Required',
    },
    logo: {},
    role: {
      required: 'Required',
    },
    password: {
      required: 'Required.',
      minlength: `Password must contain at least ${password.length.min} characters`,
      maxlength: `Password must contain only ${password.length.max} characters`,
      lowerCaseCheckFailed:
        'Password must contain at least 1 Lowercase character',
      upperCaseCheckFailed:
        'Password must contain at least 1 Uppercase character',
      numberCheckFailed: 'Password must contain at least 1 numeric character',
      specialCharCheckFailed: 'Password must contain a Special character',
      containsSpaceCheckFailed:
        'Password Cannot start with or contain space in between',
    },
    confirmPassword: {
      required: 'Required.',
      passwordMatchFailed: 'Confirm Password should be same as Password.',
    },
    searchRole: {},
  };
  roleFilterOptions = {list: [], loading: false};

  thumbnailUrl: string = '';
  isActive: number = 1;

  form = new FormGroup(
    {
      client: new FormControl({value: null, disabled: false}, [
        Validators.required,
      ]),

      searchClient: new FormControl({value: '', disabled: false}),
      name: new FormControl({value: null, disabled: false}, [
        Validators.required,
      ]),
      emailId: new FormControl(
        {
          value: null,
          disabled: false,
        },
        [Validators.required, Validators.pattern(this.validation.email.regex)]
      ),
      logo: new FormControl({value: '', disabled: true}),
      role: new FormControl({value: null, disabled: false}, [
        Validators.required,
      ]),
      searchRole: new FormControl({value: '', disabled: false}),
      password: new FormControl(
        {
          value: null,
          disabled: false,
        },
        [
          Validators.minLength(this.validation.password.length.min),
          Validators.maxLength(this.validation.password.length.max),
          this.commonService.validatePassword,
        ]
      ),
      confirmPassword: new FormControl({value: null, disabled: false}),
    },
    {validators: [this.validateConfirmPassword]}
  );

  acceptedThumbnailFileTypes = [
    'image/jpeg', // JPG / JPEG
    'image/png', // PNG
  ];
  clientFilterOptions = {
    list: [],
    loading: false,
  };
  originalClientFilterOptionsList: any[];
  originalRoleFilterOptionsList: any[];
  file: any;
  clientId: any;
  isUpdatePasswordClicked: boolean = false;
  role: any;
  onClientsProfilePage: boolean;
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private commonService: CommonService,
    private router: Router,
    private store: Store<fromRoot.State>,
    private cdRef: ChangeDetectorRef
  ) {
    this.store.select(profile)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.role = data?.role;
        if (data?.role === 'Admin') {
          this.clientId = ''
        } else {
          this.clientId = data.client_id
          this.form.controls.client.removeValidators([Validators.required])
        }
      });

    this.onClientsProfilePage = !!router.url.includes('client-details')

    if (this.onClientsProfilePage || this.role !== 'Admin') {
      this.form.controls.client.removeValidators([Validators.required]);
    }

    this.store.dispatch(FetchRoleFilterRequest({}));
    this.fetchClientFilters()

    this.store
      .select(roleFilter)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data) => {
        this.originalRoleFilterOptionsList = data?.list;
        this.roleFilterOptions.list = data?.list;
        this.roleFilterOptions.loading = data?.loading;
      });

    this.store
      .select(clientFilter)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data) => {
        this.clientFilterOptions.list = data?.list;
        this.originalClientFilterOptionsList = data?.list;
        this.clientFilterOptions.loading = data?.loading;
      });

    this.form.controls.searchClient.valueChanges
      .pipe(debounceTime(100), takeUntil(this.onDestroy))
      .subscribe((data) => {
        if (data) {
          this.clientFilterOptions.list = this.originalClientFilterOptionsList.filter((item) => {
            return item?.value?.toLowerCase().includes(data.toLowerCase());
          });
        } else {
          this.clientFilterOptions.list = this.originalClientFilterOptionsList;
        }
      });

    this.form.controls.searchRole.valueChanges
      .pipe(debounceTime(100), takeUntil(this.onDestroy))
      .subscribe((data) => {
        if (data) {
          this.roleFilterOptions.list = this.originalRoleFilterOptionsList.filter((item) => {
            return item?.value?.toLowerCase().includes(data.toLowerCase());
          });
        } else {
          this.roleFilterOptions.list = this.originalRoleFilterOptionsList;
        }
      });


  }

  ngOnInit() {
    const tempData = JSON.parse(JSON.stringify(this.data));
    if (tempData?.userData) {
      this.userData = tempData?.userData;
      this.form.controls['emailId'].disable();
      this.form.patchValue(
        {
          name: this.userData?.display_name,
          emailId: this.userData?.email,
          role: this.userData?.role,
          client: this.userData?.client_id,
        },
        {emitEvent: false}
      );
      this.thumbnailUrl = this.userData?.image_url;
      this.isActive = Number(!!this.userData?.active_flag);
      this.cdRef.detectChanges();
    }

    if (tempData?.userProfileDetails) {
      this.userProfileDetails = tempData?.userProfileDetails;
      this.form.controls['emailId'].disable();
      this.form.patchValue(
        {
          name: this.userProfileDetails?.display_name,
          emailId: this.userProfileDetails?.email,
          role: this.userProfileDetails?.role,
          client: this.userProfileDetails?.client_id,
        },
        {emitEvent: false}
      );

      this.thumbnailUrl = this.userProfileDetails.image_url;
      this.isActive = Number(!!this.userProfileDetails.active_flag);

      this.cdRef.detectChanges();
    }

    if (this.userData || this.userProfileDetails) {
      this.removePasswordFormControls();
      this.displayPasswordSection = false
    } else {
      this.displayPasswordSection = true
      this.addPasswordFormControls();
    }
  }

  openFile(id) {
    $(`#${id}`).click();
  }

  save() {
    if (this.form.invalid) {
      this.checkForErrors();
      return;
    }

    const {client, name, emailId, role, password} = this.form.getRawValue();

    const payload = {
      display_name: name,
      email: emailId,
      role,
      image_file: this.file,
      active_flag: Number(!!this.isActive),
      password,
      ...(this.userData ? {user_id: this.userData?.user_id} : {}),
      ...(this.userProfileDetails ? {user_id: this.userProfileDetails.user_id} : {}),
      ...(this.userData || this.userProfileDetails ? {client_id: this.clientId} : {}),
      ...(this.role === 'Admin' ? {client_id: client} : {}),
      ...(this.role === 'Admin' && this.data?.clientIdFromClientProfilePage ? {client_id: this.data?.clientIdFromClientProfilePage} : {}),

      accepted_TOS_flag: 0
    };

    if (this.userData || this.userProfileDetails) {
      this.store.dispatch(UpdateUserRequest({payload}));
    } else {
      this.store.dispatch(AddNewUserRequest({payload}));
    }
  }

  validateConfirmPassword(control: AbstractControl): ValidationErrors | null {
    if (control && control.get('password') && control.get('confirmPassword')) {
      const newPassword = control.get('password').value;
      const confirmNewPassword = control.get('confirmPassword')?.value;
      if (newPassword &&
        !confirmNewPassword) {
        control.get('confirmPassword').setErrors({
          ...(control.get('confirmPassword').errors
            ? control.get('confirmPassword').errors
            : {}),
          required: true,
        });
      }
      if (
        newPassword &&
        confirmNewPassword &&
        newPassword !== confirmNewPassword
      ) {
        control.get('confirmPassword').setErrors({
          ...(control.get('confirmPassword').errors
            ? control.get('confirmPassword').errors
            : {}),
          passwordMatchFailed: true,
        });
      }
    }
    return null;
  }

  tooltipMessage() {
    return `\u2022 Must contain at least ${this.validation.password.length.min} characters;
     \n \u2022 Password must contain only ${this.validation.password.length.max} characters;
     \n \u2022 Password must contain at least 1 Lowercase character;
     \n \u2022 Password must contain at least 1 Uppercase character;
     \n \u2022 Password must contain at least 1 numeric character;
     \n \u2022 Password must contain a Special character;
     \n \u2022 Password Cannot start with or contain space in between`;
  }

  checkForErrors(currentField?: string) {
    this.errorMessages = {
      ...this.errorMessages,
      ...this.commonService.checkFormValidation(
        this.form,
        this.errorMessageMap,
        currentField
      ),
    };
  }

  handleThumbnailUpload() {
    this.file = this.thumbnailUploadRef.nativeElement.files[0];
    const file = this.thumbnailUploadRef.nativeElement.files[0];
    if (file) {
      if (!this.acceptedThumbnailFileTypes.includes(file?.type)) {
        this.commonService.notification('Invalid file type.', 'danger');
        return;
      }

      if (this.file.size > 5242880) {
        this.commonService.notification('File size larger than 5 mb', 'danger');
        return;
      }

      this.thumbnailUrl = URL.createObjectURL(file);

      this.form.controls.logo.setValue(file.name, {emitEvent: false});
    }

    this.thumbnailUploadRef.nativeElement.value = '';
    this.form.markAsDirty();
  }


  toggle(event: MatSlideToggleChange) {
    this.isActive = +event.checked;
  }

  togglePasswordClicked(event: MatSlideToggleChange) {
    this.isUpdatePasswordClicked = event.checked;
    this.openUpdatePasswordForm();
  }

  fetchClientFilters() {
    let payload = {
      id: this.clientId
    }
    this.store.dispatch(FetchClientFilterRequest({payload}));
  }

  openUpdatePasswordForm() {
    this.displayPasswordSection = !this.displayPasswordSection;
    if (this.displayPasswordSection) {
      this.addPasswordFormControls();
    } else {
      this.removePasswordFormControls();
    }
    this.form.updateValueAndValidity();
  }

  addPasswordFormControls() {
    this.form.addControl('password', new FormControl(
      {
        value: null,
        disabled: false,
      },
      [
        Validators.minLength(this.validation.password.length.min),
        Validators.maxLength(this.validation.password.length.max),
        this.commonService.validatePassword,
      ]
    ));
    this.form.addControl('confirmPassword', new FormControl({value: null, disabled: false}))
    this.form.addValidators([this.validateConfirmPassword]);
    this.form.updateValueAndValidity();
  }

  removePasswordFormControls() {
    this.form.removeControl('confirmPassword');
    this.form.removeControl('password');
    this.form.removeValidators([this.validateConfirmPassword]);
    this.form.updateValueAndValidity();
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }

}
