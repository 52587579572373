<div class="app-header flex justify-between">
  <div class="logo">
    <app-image
      url="/assets/images/webp/logo.webp"
    ></app-image>
  </div>
  <app-translation-selector
    *ngIf="displayTranslationSelector"/>
</div>

<div class="app-content relative">
  <router-outlet></router-outlet>
  <ng-content></ng-content>
</div>

<div class="app-footer">
</div>
