export const config = [
  {
    key: 'manageUserRoles',
    label: 'Manage User Roles',
    link: '/manage-user-roles'
  },
  // PLEASE DO NOT DELETE ::: Commented Code Kept as it may be needed later-on

  // {
  //   label: 'Users',
  //   children: [
  //     ...(
  //       environment.env === 'local' ? [
  //         {
  //           key: 'manageUsersNew',
  //           label: 'Manage Users (New)',
  //           link: '/users/new'
  //         }
  //       ] : []
  //     ),
  //     {
  //       key: 'manageUsers',
  //       label: 'Manage Users',
  //       link: '/users'
  //     },
  //     {
  //       key: 'manageSchoolOfUsers',
  //       label: 'Manage School of Users',
  //       link: '/users/schools'
  //     },
  //   ]
  // },
  // {
  //   label: 'Clients',
  //   children: [
  //     ...(
  //       environment.env === 'local' ? [
  //         {
  //           key: 'manageClients',
  //           label: 'Manage Clients',
  //           link: '/clients'
  //         }
  //       ] : []
  //     ),
  //     {
  //       key: 'manageSchoolsOfClients',
  //       label: 'Manage Schools of Clients',
  //       link: '/clients/schools'
  //     },
  //   ]
  // },

  // PLEASE DO NOT DELETE ::: Commented Code Kept as it may be needed later-on

  {
    key: 'manageUsers',
    label: 'Manage Users',
    link: '/users'
  },
  {
    key: 'manageClients',
    label: 'Manage Clients',
    link: '/clients'
  },
  {
    key: 'manageSchools',
    label: 'Manage Schools',
    link: '/schools'
  },
  {
    label: 'Surveys',
    children: [
      {
        key: 'surveyVersions',
        label: 'Survey Versions',
        link: '/survey/versions'
      },
      {
        key: 'createSurvey',
        label: 'Create Survey',
        link: '/survey/create'
      }
    ]
  },
  {
    key: 'schoolYears',
    label: 'School Years',
    link: '/school-years'
  }
];
