<div
  *ngIf="parentFormGroup?.controls[controlName]"
  class="font-work-sans font-regular"
>
  <div
    *ngIf="label"
    [class.error]="!!error"
    class="form-field-label {{type}}"
  >
    {{ label }}
    <span
      *ngIf="isRequired"
      class="required-mark"
    >*</span>
  </div>
  <mat-form-field
    [formGroup]="parentFormGroup"
    [ngClass]="type ? 'small' : ''"
    appearance="outline"
    class="form-field w-full"
    hideRequiredMarker
  >
    <mat-icon
      *ngIf="prefixIcon"
      [svgIcon]="prefixIcon"
      class="cursor-pointer"
      matPrefix
    ></mat-icon>
    <mat-icon
      (click)="suffixIconClicked()"
      *ngIf="suffixIcon && !searchClicked"
      [matTooltip]="suffixIcon === 'search' ? 'Search':''"
      [svgIcon]="(suffixIcon === 'search' && parentFormGroup?.controls[this.controlName]?.value?.length > 0) ? 'search' : (suffixIcon !== 'search' ? suffixIcon : 'disabled-search')"
      class="cursor-pointer"
      matSuffix
    ></mat-icon>
    <mat-icon
      (click)="clearSelection($event)"
      *ngIf="searchClicked"
      class="cursor-pointer"
      matSuffix
      matTooltip="Clear"
    >
      close
    </mat-icon>
    <i
      (click)="handleSuffixClick.emit()"
      *ngIf="faIcon"
      class="fa {{faIcon}}"
      matSuffix
    >
    </i>
    <input
      #input
      [formControlName]="controlName"
      [maxlength]="maxLength"
      [minlength]="minLength"
      class="input {{type}} text-input"
      matInput
      placeholder="{{placeholder}}"
      type="text"
    >
    <mat-error *ngIf="error">{{ error }}</mat-error>
  </mat-form-field>
</div>
