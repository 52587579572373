/* NgRx */
import {createAction, props} from '@ngrx/store';

/******** CLEAR STATE ***********/
const CLEAR_STATE = '[users list page] clear state';
export const ClearState = createAction(CLEAR_STATE);

/******** FETCH USER LIST ***********/
const FETCH_USER_LIST_REQUEST = '[users list page] fetch user list request';
export const FetchUserListRequest = createAction(
  FETCH_USER_LIST_REQUEST,
  props<any>()
);

const FETCH_USER_LIST_SUCCESS = '[users list page] fetch user list success';
export const FetchUserListSuccess = createAction(
  FETCH_USER_LIST_SUCCESS,
  props<any>()
);

const FETCH_USER_LIST_FAILURE = '[users list page] fetch user list failure';
export const FetchUserListFailure = createAction(
  FETCH_USER_LIST_FAILURE,
  props<any>()
);

/*********** FETCH CLIENT FILTER **************/
const FETCH_CLIENT_FILTER_REQUEST =
  '[users list page] fetch client filter requested';
export const FetchClientFilterRequest = createAction(
  FETCH_CLIENT_FILTER_REQUEST,
  props<any>()
);

const FETCH_CLIENT_FILTER_SUCCESS =
  '[users list page] fetch client filter successful';
export const FetchClientFilterSuccess = createAction(
  FETCH_CLIENT_FILTER_SUCCESS,
  props<any>()
);

const FETCH_CLIENT_FILTER_FAILURE =
  '[users list page] fetch client filter failure';
export const FetchClientFilterFailure = createAction(
  FETCH_CLIENT_FILTER_FAILURE,
  props<any>()
);

/*********** FETCH SCHOOL FILTER **************/
const FETCH_SCHOOL_FILTER_REQUEST =
  '[users list page] fetch school filter requested';
export const FetchSchoolFilterRequest = createAction(
  FETCH_SCHOOL_FILTER_REQUEST,
  props<any>()
);

const FETCH_SCHOOL_FILTER_SUCCESS =
  '[users list page] fetch school filter successful';
export const FetchSchoolFilterSuccess = createAction(
  FETCH_SCHOOL_FILTER_SUCCESS,
  props<any>()
);

const FETCH_SCHOOL_FILTER_FAILURE =
  '[users list page] fetch school filter failure';
export const FetchSchoolFilterFailure = createAction(
  FETCH_SCHOOL_FILTER_FAILURE,
  props<any>()
);

/*********** FETCH ROLE FILTER **************/
const FETCH_ROLE_FILTER_REQUEST =
  '[users list page] fetch role filter requested';
export const FetchRoleFilterRequest = createAction(
  FETCH_ROLE_FILTER_REQUEST,
  props<any>()
);

const FETCH_ROLE_FILTER_SUCCESS =
  '[users list page] fetch role filter successful';
export const FetchRoleFilterSuccess = createAction(
  FETCH_ROLE_FILTER_SUCCESS,
  props<any>()
);

const FETCH_ROLE_FILTER_FAILURE = '[users list page] fetch role filter failure';
export const FetchRoleFilterFailure = createAction(
  FETCH_ROLE_FILTER_FAILURE,
  props<any>()
);

/******** UPDATE USER ***********/
const UPDATE_USER_REQUEST = '[users list page] update user request';
export const UpdateUserRequest = createAction(
  UPDATE_USER_REQUEST,
  props<any>()
);

const UPDATE_USER_SUCCESS = '[users list page] update user success';
export const UpdateUserSuccess = createAction(
  UPDATE_USER_SUCCESS,
  props<any>()
);

const UPDATE_USER_FAILURE = '[users list page] update user failure';
export const UpdateUserFailure = createAction(
  UPDATE_USER_FAILURE,
  props<any>()
);
